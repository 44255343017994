import { ENV } from "../../../../src/app/interfaces/interfaces";

export const environment: ENV = {
    production: false,
    apiUrl: 'https://thestrongestmembresias.test.solunes.com/',
    type: 'the-strongest',
    title: 'The Strongest',
    subtitle: 'Membresías',
    description: `"The Strongest - Membresías" es un sitio en el que puedes administrar tus abonos.`,
    keywords: 'club, the strongest, strongest, tigre, abonos, entradas, tickets',
    yearProyect: '2024',
    imgLogo: '/assets/img/logo.png',
    imgLogoSVG: '/assets/img/logo.svg',
    imgLogoBig: '/assets/img/logo-big.png',
    templateType: 'template-2',
    theme: 'dark',
    changeTheme: false,

    dashboard: false,
    disclaimer: false,
    header: true,
    sidebar: true,
    footer: true,
    preFooter: false,

    authType: 'auth-2',
    authGoogle: false,
    authFacebook: false,
    authApple: false,
    authGithub: false,
    textToLogin: '¿Ya tienes una cuenta?',
    textToRegister: '¿Todavía no eres miembro?',

    authRedirect: 'shop',

    processCartType: 'type-3',
    processCartTitle: 'Conviértete en Aviador',

    multiplePayments: false,
    multipleProfiles: true,

    loaderType: 3,
    loaderDefaultText: '',

    //* Firebase
    firebase: {
        "projectId":"clubbolivar-membresias",
        "appId":"1:924925915724:web:9f327aa12495b5d7c9fae9",
        "storageBucket":"clubbolivar-membresias.appspot.com",
        "apiKey":"AIzaSyBn7I3w7JFAJcP-AVJNWhht25jhyGUgN2M",
        "authDomain":"clubbolivar-membresias.firebaseapp.com",
        "messagingSenderId":"924925915724",
        "measurementId":"G-J741BLEVFD",
    },
    
    keyEncript: '12345678912345678',
    ivEncript: '12345678912345678',
};
